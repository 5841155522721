export default () => ([
	{
		href: '/gallery',
		title: 'Галерея',
	},
	{
		href: '/partners/commercial',
		title: 'Партнерам',
		sub: [
			{
				href: '/partners/commercial',
				title: 'Размещение рекламы',
			},
			{
				href: '/partners/rent',
				title: 'Аренда залов',
			},
		],
	},
]);
